/*Coeca custom JS*/

(function ($) { 

    $(document).ready(function () {
            
            $(".menu-hamburger a").click(function (e) {
                e.preventDefault();
                $('.main-navigation').toggleClass('show');
            });

            $('.menu-item-has-children').hover(function (e) {
                e.preventDefault();
                /**/
                if ($(this).find('.sub-menu').hasClass('show')) {
                    $('.main-navigation').find('.sub-menu').removeClass('show');
                } else {
                    $('.main-navigation').find('.sub-menu').removeClass('show');
                    $(this).find('.sub-menu').toggleClass('show');
                }
            });

            $("#toTop").click(function (e) { 
                $('html, body').animate({
                    scrollTop: $("#top").offset().top
                }, 1000);
                e.preventDefault(); 
            });

            /*
            * Clona el menu top en moviles o lo elimina en desktop
            */
            cloneMenuTop();
            
            $(window).resize(function () {
                cloneMenuTop();
            });

            
            /*
            * Calcular la altura del formulario de call me.
            */
            setCallMeHeigth();

            window.onload = function () {
                setCallMeHeigth();
            }

            $(window).resize(function() {
                setCallMeHeigth();
            });


            /*
            * ocultar y mostrar el formulario call me
            */
            $(".toggle-window").click(function (e) {
                e.preventDefault();
                $(".call-me").toggleClass('show');

            }); 
            $(".collapse").click(function (e) {
                e.preventDefault();
                $(".div-collapsed").toggleClass('show');
            });

            /*
            * Dejar visible el formulario cuando exista el ancla en la url.
            *  - formulario con errores o con mensaje de gracias.
            */
            if (window.location.hash === "#sf_form_salesforce_w2l_lead_3") {
                var $divCollapsed = $(".call-me");
                if( !$divCollapsed.hasClass('show')) {
                    $divCollapsed.addClass('show');     
                }
            }

            /*
            * apertura de la modal de los diferentes avisos de privacidad
            */
            $('.modal-open').click(function(e){
                var dataModal = $(this).data('modal');
                $('#modal-overlay-' + dataModal).addClass('modal-overlay-on');
                $('#modal-content-' + dataModal).addClass('modal-content-on');
                e.preventDefault();
            });
            

            /*
            * cierre de la modal de los diferentes avisos de privacidad
            */
            $('.modal-close a').click(function(e){
                var dataModal = $(this).data('modal');
                $('#modal-overlay-' + dataModal).removeClass('modal-overlay-on');
                $('#modal-content-' + dataModal).removeClass('modal-content-on');
                e.preventDefault();
            });

            
            /*
            * click en los botones de aceptar la politica de privacidad de las modales.
            */
            $(".modal-accept").click(function(e){
                var dataModal = $(this).data('modal');
                $('#chk-aviso-legal-' + dataModal).prop('checked', true).change(); // call change to trigger change event
                $('#modal-overlay-' + dataModal).removeClass('modal-overlay-on');
                $('#modal-content-' + dataModal).removeClass('modal-content-on');
                e.preventDefault(); 
            });


            /*
            * Inicializar el estado del boton de submit
            */
            $("[id^=chk-aviso-legal-]").each(function(){
                setSubmitBtnStatus(this);
            });


            /*
            * escuchar los cambios al check de aviso y determinar el estado del btn submit
            */
            $("[id^=chk-aviso-legal-]").change(function(){
               setSubmitBtnStatus(this);
            });


            /*
            * añadir automaticamente el valor para el campo hidden del formulario de contacto_llamada
            * el identificador es el mismo para ambos sitios.
            */
            if ( $('#sf_form_salesforce_w2l_lead_3').length ) {
                $('#sf_form_salesforce_w2l_lead_3').find('#sf_00N20000003YzY0').val(derten.parent_title);    
            }
            

            /*
            * Formulario trabaja con nosotros-profesional, 'To' dinamico segun opciones del formulario.
            *
            * Las direcciones de email se definen en el fichero function.
            *
            * Obtener via objeto derten:
            *   - derten.trabaja_con_nosotros_ciberseguridad
            *   - derten.trabaja_con_nosotros_sistemas
            *
            * Dependiendo del valor de los radios enviamos a direcciones diferentes
            */
            if ( $('#wpcf7-f618-o2').length && $('#mailto').length ) {
                setTrabajaConNosotrosProfesionalEmailTo();

                $("input[name='radio-tipo']").change(function(){
                    setTrabajaConNosotrosProfesionalEmailTo();
                });
            }
            
    });

    $(document).ready(function() {
        var $modal = $('#veissModal'),
            politicaInput = '#politica input',
            politicaSpan = '#politica span',
            privacidad = $(politicaInput).prop('checked');
    
        /**
         * 1. La política no está aceptada: mostrar modal.
         * 2. La política ya está aceptada: desmarcar.
         */
        $(document).on('click', politicaInput, function(e) {
            if (!privacidad) {
                e.preventDefault();
                modalPrivacidad();
    
                return;
            }
    
            toggleChecks(false);
        });
    
        /**
         * Los clicks en el label de la política de privacidad abren el modal.
         */
        $(document).on('click', politicaSpan, function(e) {
            modalPrivacidad();
        });
    
        /**
         * Acciones a realizar sí se reinicia el formulario.
         */
        $(politicaInput).closest('form').on('reset', function(event) {
            setTimeout(function() {
                privacidad = $(politicaInput).prop('checked');
            }, 1);
        });
    
        /**
         * Mostrar modal para la privacidad.
         */
        function modalPrivacidad() {
            $modal.veissModal({
                id: 'pol',
                buttons: [{
                    id: 'polnook',
                    class: 'btn btn-default modal-clear',
                    click: function() { toggleChecks(false); }
                }, {
                    id: 'polok',
                    class: 'btn btn-primary btn-serserve-on',
                    click: function() { toggleChecks(true); }
                }]
            });
        }
    
        /**
         * Cambiar el valor de la propiedad checked.
         */
        function toggleChecks(value) {
            privacidad = value;
            $(politicaInput).prop('checked', value);
        }
    });    

})(jQuery);

(function($) {
    var that,
        settings;

    $.fn.veissModal = function(options, element) {
        settings = $.extend({
            id: '',
            class: '',
            icon: '',
            buttons: [],
        }, options);

        element = $(element || this.defaultElement || this)[0];
        this.element = $(element);
        that = this;

        // Contenido de la modal.
        setClasses();
        setHeader();
        setBody();
        setFooter();

        // Al cerrar el modal, hacer scroll a la parte superior del contenido.
        this.on('hide.bs.modal', function() {
            $(element).find('.modal-body').scrollTop(0);
        });

        this.modal('show');
    };

    /**
     * Limpiar clases del contenido.
     * AÃ±adir las clases que se incluyen en la configuraciÃ³n.
     */
    function setClasses() {
        var contentClass = 'modal-content' + ' ' + settings.class;
        that.find('.modal-content').attr('class', contentClass);
    }

    /**
     * Cabecera de la modal.
     * Puede ser HTML.
     */
    function setHeader() {
        var header = that.find('.modal-header'),
            headerTxt = $('#' + settings.id + ' div.header');

        header.empty();

        if (0 == headerTxt.length) {
            header.hide();
            return;
        }

        header.show();
        header.append(headerTxt.html());
    }

    /**
     * Contenido de la modal.
     * Puede ser un icono o HTML.
     */
    function setBody() {
        var icon = settings.icon,
            body = that.find('.modal-body'),
            bodyTxt = $('#' + settings.id + ' div.body');

        body.empty();

        if (0 == bodyTxt.length && '' == icon) {
            body.hide();
            return;
        }

        body.show();

        if ('' != icon) {
            var img = '<div><img src="' + icon + '"/></div>';
            body.append(img);
        }

        if (0 < bodyTxt.length) {
            body.append(bodyTxt.html());
        }
    }

    /**
     * Pie de la modal.
     * Puede ser HTML o botones.
     */
    function setFooter() {
        var buttons = settings.buttons,
            footer = that.find('.modal-footer'),
            footerTxt = $('#' + settings.id + ' div.footer');

        footer.empty();

        if (0 == footerTxt.length && 0 == buttons.length) {
            footer.hide();
            return;
        }

        footer.show();

        if (0 < footerTxt.length) {
            footer.append(footerTxt.html());
        }

        // Botones del modal.
        $.each(buttons, function(name, props) {
            var buttonDiv = $('#' + settings.id + ' div.' + props.id);

            if (0 == buttonDiv.length) {
                return; // Equivalente a continue en PHP.
            }

            props.text = buttonDiv.html();

            var click, buttonOptions;
            props = $.isFunction(props) ?
                { click: props, text: name } :
                props;
            props = $.extend({ type: 'button' }, props);

            click = props.click;
            if (undefined != click) {
                props.click = function() {
                    click.apply(that.element[0], arguments);
                };
            }

            buttonOptions = {
                icons: props.icons,
                text: props.showText
            };

            if (!props.noClose) {
                props['data-dismiss'] = 'modal';
            }

            delete props.icons;
            delete props.noClose;
            delete props.showText;

            $('<button></button>', props)
                .addClass('btn')
                .button(buttonOptions)
                .appendTo(footer);
        });
    }
} (jQuery));



function setSubmitBtnStatus(element) {
    var $element = $(element);
    var $form    = $element.closest('form');
    var $submit  = $form.find(':submit');
    
    $submit.prop('disabled',!$element.is(':checked'));
}

function setCallMeHeigth() {
    var formHeight = $('.call-me-form').outerHeight();
    $(".call-me").css('bottom',-formHeight);
}

//Clona el menu top en moviles o lo elimina en desktop
function cloneMenuTop ()
{

    var cloneWidth = 992;
    var windowWidth = $(window).innerWidth();

    if (windowWidth <= cloneWidth)
    {

        if ( $('#menu-menu-top-cloned').length == 0 )
        {

            $('#menu-menu-top')
                .clone()
                .appendTo('.menu-menu-principal-container')
                .attr('id', 'menu-menu-top-cloned');
                
        }
        
    }
    else
    {
        $('#menu-menu-top-cloned').remove();
    }

}

/*
* asigna el valor para el campo mailto,
* dependiendo de la opcion seleccionada en el formulario para el campo "radio-tipo".
*/
function setTrabajaConNosotrosProfesionalEmailTo() {
    
    var $mailto       = $('#mailto'); 
    var $radioButtons = $("input[name='radio-tipo']");
    var selectedIndex = $radioButtons.index($radioButtons.filter(':checked'));
    var mailtoAddress = derten.admin_email;
    
    /*console.log($mailto);
    console.log("current blog id: " + derten.current_blog_id);
    console.log(derten.sti_trabaja_ciberseguridad);
    console.log(derten.sti_trabaja_sistemas);
    console.log(derten.salesforce_trabaja_desarrollo);
    console.log(derten.salesforce_trabaja_comercial);
    console.log(derten.salesforce_trabaja_consultoria);
    console.log(selectedIndex);*/
    
    if( $mailto.length ) {
        switch(derten.current_blog_id) {
            case '2':
                switch(selectedIndex) {
                    case 1:
                        mailtoAddress = derten.sti_trabaja_sistemas;
                        break;
                    default:
                         mailtoAddress = derten.sti_trabaja_ciberseguridad;
                } 
                break;
            case '3':
                switch(selectedIndex) {
                    case 1:
                        mailtoAddress = derten.salesforce_trabaja_desarrollo;
                        break;
                    case 2:
                        mailtoAddress = derten.salesforce_trabaja_comercial;
                        break;
                    default:
                         mailtoAddress = derten.salesforce_trabaja_consultoria;
                } 
                break;
        }

        $mailto.val(mailtoAddress); 
    }
}